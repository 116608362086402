<template>
  <div>
    <big-title>
      Sugerencias de <span>seguridad</span>.
    </big-title>

    <vx-card title="En la Red queremos que estés seguro.">
      <div class="vx-col w-full">
        <p>
          La seguridad juega un papel muy importante en el cuidado de la información y de todas tus operaciones,
          por lo que queremos compartirte una serie de medidas y recomendaciones que harán que tu experiencia en la
          Red sea aún más segura. ¿Estás listo para ser un experto en seguridad?
        </p>
      </div>
    </vx-card>

    <!-- swiper 1 -->
    <vx-card class="mt-5">
      <swiper class="swiper" :options="swiperOption">
        <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%" :style="{ backgroundImage: bg }"></div>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Nuestra plataforma.</div>
          <div class="subtitle" data-swiper-parallax="-240">Verifica la URL en tu navegador.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>Es de suma importancia que verifiques detalladamente que la URL del navegador sea exactamente
              <span class="bold">https://www.redgirasol.com</span> ya que en algunos casos, los navegadores no muestran el URL completo,
              por lo que podría aparecer únicamente redgirasol.com. Asegúrate que aparezca el resto de la liga, al hacer clic.</p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Nuestra plataforma.</div>
          <div class="subtitle" data-swiper-parallax="-240">Entrar en el sitio web escribiendo la URL directamente en el navegador.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>Evita entrar a través de otros enlaces externos.
              Al entrar en <span class="bold">https://www.redgirasol.com</span> verifica en tu navegador que a un lado de la URL aparezca un candado cerrado. Si tiene la forma de un candado abierto, estarás en un sitio falso.
              No realices ninguna transacción hasta que te asegures que te encuentras en el sitio oficial de RedGirasol®.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Nuestra plataforma.</div>
          <div class="subtitle" data-swiper-parallax="-240">RedGirasol® nunca te pedirá tu contraseña.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              Además de que no te solicitará ningún tipo de información personal o financiera a través de otro medio que no sea el
              sitio web oficial <span class="bold">https://www.redgirasol.com</span> o los medios de contacto oficiales de RedGirasol®.
            </p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-custom" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </vx-card>
    <!-- fin swiper 1 -->

    <!-- swiper 2 -->
    <vx-card class="mt-5">
      <swiper class="swiper" :options="swiperOption">
        <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%" :style="{ backgroundImage: bg }"></div>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Tu contraseña.</div>
          <div class="subtitle" data-swiper-parallax="-240">Sigue los criterios de una contraseña segura.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              Tener una contraseña de mínimo 8 caracteres, que incluya al menos una letra minúscula, una letra mayúscula, un número y un caracter alfanúmerico.
              No comentar a nadie el patrón utilizado para formar la contraseña, no anotarla, usar técnicas para memorizarla.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Tu contraseña.</div>
          <div class="subtitle" data-swiper-parallax="-240">Procura no usar información que otros puedan saber de ti.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              No incluir palabras relacionadas a cosas que cualquier persona pudiera saber de ti,
              como tu nombre y/o apellidos al derecho o al revés, fechas importantes como cumpleaños,
              aniversarios, etc., el nombre de personas muy cercanas a ti como tu pareja, familiares o amigos cercanos,
              el nombre de tu mascota, el nombre de tu equipo deportivo, conjunto musical, o comida favorito(s), etc.
            </p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-custom" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </vx-card>
    <!-- fin swiper 2 -->

    <!-- swiper 3 -->
    <vx-card class="mt-5">
      <swiper class="swiper" :options="swiperOption">
        <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%" :style="{ backgroundImage: bg }"></div>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Tu correo electrónico.</div>
          <div class="subtitle" data-swiper-parallax="-240">¡No te dejes engañar!</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              Procura revisar detalladamente tus correos electrónicos, verifica que la dirección del remitente sea de quien dice ser,
              el nombre y la arroba (@) de la cuenta de correo de RedGirasol® deben de estar escritos correctamente en cada correo electrónico que recibas.
              Puedes tomar el siguiente cuestionario creado por Google para que aprendas como puedes ser engañado a
              través de este medio <span class="bold">https://phishingquiz.withgoogle.com/</span>
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Tu correo electrónico.</div>
          <div class="subtitle" data-swiper-parallax="-240">Evita las descargas sospechosas.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              No descargues cualquier archivo adjunto que se te sea enviado, examina la URL de los enlaces dentro del contenido del correo
              ya que te pueden redireccionar a sitios falsos.
              Te recomendamos contar con un antivirus reconocido que evite la descarga no autorizada de archivos que puedan poner tu información en riesgo.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">Tu correo electrónico.</div>
          <div class="subtitle" data-swiper-parallax="-240">No compartas tus correos.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              No reenvíes correos electrónicos que recibes de RedGirasol®,
              sobre todo si contienen ligas de activación, de renovación de contraseña, etc.
            </p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-custom" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </vx-card>
    <!-- fin swiper 3 -->

    <!-- swiper 4 -->
    <vx-card class="mt-5">
      <swiper class="swiper" :options="swiperOption">
        <div class="parallax-bg" slot="parallax-bg" data-swiper-parallax="-23%" :style="{ backgroundImage: bg }"></div>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">¡Cuídate!</div>
          <div class="subtitle" data-swiper-parallax="-240">Protege tu cuenta y mantente alerta.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              Ante cualquier anomalía que detectes en tu cuenta o dentro de la página no dudes en contactarnos
              a través del chat en redgirasol.com. No compartas cualquier tipo de información personal o
              confidencial en las redes sociales.
            </p>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="title" data-swiper-parallax="-100">¡Cuídate!</div>
          <div class="subtitle" data-swiper-parallax="-240">Manten tus claves seguras.</div>
          <div class="text" data-swiper-parallax="-360">
            <p>
              Al activar el doble factor de autenticación, no compartir el código secreto para evitar
              el robo de cuenta, y operaciones no autorizadas.
              Procura no introducir tu contraseña o tu segundo factor de autenticación en lugares públicos para evitar el robo de tu cuenta.
            </p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-custom" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </vx-card>
    <!-- fin swiper 4 -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: "SecurityTips",
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      swiperOption: {
        speed: 600,
        parallax: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      bg: `url(${require('@assets/images/pages/pattern01_sm_dark.jpg')})`,
    }
  }
}
</script>

<style lang="scss">
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  background-size: contain;
  background-position: left;
  //background-image: url('/images/example/1.jpg');
}

.swiper-pagination-custom {
  .swiper-pagination-bullet {
    background: white !important;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: rgba(var(--vs-primary), 1) !important;
  }
}

.swiper {
  width: 100%;
  height: 380px;

  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    box-sizing: border-box;
    padding: 0 80px;
    background-color: transparent;

    .title {
      margin-bottom: 0.75rem;
      font-size: 3.25rem;
      line-height: 2.5rem;
    }

    .subtitle {
      margin-bottom: 0.85rem;
      font-size: 1.7rem;
      font-family: 'Gilroybold',sans-serif !important;
      font-weight: normal !important;
      line-height: 1.75rem;
    }

    .text {
      max-width: 80%;
      line-height: 1.5rem;
      font-size: 1.25rem;
    }
  }
}


@media (max-width: 800px){
  .swiper {
    height: 400px;

    .swiper-slide {
      padding: 30px 60px;

      .title {
        margin-bottom: 0.75rem;
        font-size: 2rem;
        line-height: 1.5rem;
      }

      .subtitle {
        margin-bottom: 0.75rem;
        font-size: 1.2rem;
        line-height: 1.2rem;
      }

      .text {
        max-width: 95%;
        line-height: 1.25rem;
        font-size: 1rem;
      }
    }
  }
}
</style>